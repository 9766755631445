<template>
	<NuxtLayout
		name="one-column-layout"
		class="my-auto">
		<template #title>
			<div class="mt-10 flex flex-col gap-4">
				<IconsFontAwesome icon="fa-solid fa-face-frown-open" />
				<div>{{ $t("errorPageMissingTitle") }}</div>
			</div>
		</template>

		<template #subtitle>
			<p>{{ $t("errorPageMissingBody") }}</p>
		</template>

		<template #contentSlot1> </template>

		<template #contentSlot2>
			<ButtonPrimary
				class="w-60 self-center"
				@click="goToBeginning">
				<p>{{ $t("backToBeginning") }}</p>
			</ButtonPrimary>
		</template>
	</NuxtLayout>
</template>

<script setup>
	//The [...slug].vue dynamic will match all routes that have not been defined.
	//Thus, using this as a 404 not found page since the error.vue page does not render properly.

	import { clearError, useError } from "#app"

	const { error } = useError()
	const { t } = useI18n()

	const appConfig = useAppConfig()

	if (appConfig.showMaintenancePage) {
		navigateTo({ path: "/" })
	}

	//Set the Page Metadata
	useHead(() => {
		return {
			title: t("errorPageMissingTitle"),
			meta: [{ name: "description", content: `Page not found` }],
		}
	})

	const goToBeginning = () => clearError({ redirect: "/" })
</script>
